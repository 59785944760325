import { FC, ReactNode } from 'react'

type Props = {
  children: ReactNode
}

const Component: FC<Props> = ({ children }) => {
  return <section className="w-full card-list">{children}</section>
}

export default Component
