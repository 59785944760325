import { FC } from 'react'

type Props = {
  title: string
  fontSize?: string
  h2?: boolean
}

const Component: FC<Props> = ({ title, fontSize, h2 }) => {
  const titleComp = h2 ? (
    <h2 className={`text-secondary-700 font-semibold text-xl md:${fontSize}`}>{title}</h2>
  ) : (
    <p className={`text-secondary-700 font-semibold text-xl md:${fontSize}`}>{title}</p>
  )
  return (
    <div className="mt-4 my-2 md:w-full">
      {titleComp}
      <div className="xs:w-[342px] md:w-full mt-2 border-b border-1 border-divided" />
    </div>
  )
}

export default Component
