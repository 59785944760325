import { Article, BodyContent } from '@/api/types'
import { formatTime, readTime } from '@/utils'
import { parse } from 'node-html-parser'

type CardProps = Omit<Article, "body"> & {
  readTime: string
  url: string
}

export const contentModify = (articles: Article[], basePath: string): CardProps[] => {
  return articles.map(({ body, revisedAt, id, ...rest}) => {
    const rA = formatTime(revisedAt)
    const rT = readTime(body)
    const url = `${basePath}/${id}`

    return { id, revisedAt: rA, readTime: rT, url, ...rest}
  })
}

export const tableOfContents = (body: BodyContent[]) => {
  const text = body.map((b) => b.richContent).join()
  return parse(text).querySelectorAll('h2, h3').map((node) => {
    return { text: node.innerText, tag: node.tagName, id: node.id }
 })
}
