import { FC } from 'react'
import Tag from '@/components/Tag'
import { ArticleTag } from '@/api/types'
import Link from 'next/link'
import { TagPageBase } from 'src/route'

type Props = {
  tags: ArticleTag[]
}

const Component: FC<Props> = ({ tags }) => {
  return (
    <>
      <div className="flex flex-wrap gap-4 items-center justify-center md:justify-start xl:my-4 p-4 w-full xl:p-0">
        {tags.map((t) => {
          return (
            <Link key={t.id} href={TagPageBase(t.id, '1')} prefetch={false}>
              <a>
                <Tag key={t.id} name={t.name} tagColor="text-primary-900" />
              </a>
            </Link>
          )
        })}
      </div>
    </>
  )
}

export default Component
