import { FC } from 'react'
import { IconTag } from '@tabler/icons'

type Props = {
  name: string
  tagColor?: string
}

const Component: FC<Props> = ({ name, tagColor }) => {
  const color = tagColor !== undefined ? tagColor : 'text-secondary-700'
  return (
    <>
      <div className={`flex ${color} gap-1 items-center`}>
        <IconTag width="12" height="12" />
        <p className="text-sm whitespace-nowrap">{name}</p>
      </div>
    </>
  )
}

export default Component
