import { FC, useRef } from 'react'
import Link from 'next/link'
import Image from '@/components/Image'

export type Props = {
  eyeCatchingUrl: string
  title: string
  url: string
}

const Component: FC<Props> = ({ title, eyeCatchingUrl, url }) => {
  const ref = useRef<HTMLAnchorElement | null>(null)
  const onClickCard = () => {
    if (ref.current != null) {
      ref.current.click()
    }
  }

  const size = 'w=288&h=162'

  return (
    <>
      <div
        onClick={onClickCard}
        className="w-[288px] xs:h-[200px] hover:opacity-90"
      >
        <div className="rounded-[4px] aspect-video w-full shadow-md hover:shadow-xl">
          <Image url={eyeCatchingUrl} size={size} />
        </div>

        <Link href={url} prefetch={false}>
          <a
            ref={ref}
            className="text-lg word-keep-all text-center line-clamp-2 mt-2 font-semibold hover:text-primary-700 inline-block"
          >
            {title}
          </a>
        </Link>
      </div>
    </>
  )
}

export default Component
