import { FC, useState } from 'react'
import { IconMail } from '@tabler/icons'
import Input from '@/components/Input'
import { apiClient } from 'src/apiMiddleware'
import { zodResolver } from '@hookform/resolvers/zod';
import schema from '@/validations/mailMagazineSchema'
import { useForm, useController } from 'react-hook-form';
import Modal from '@/components/modal'
import { classNames } from '@/utils'
import { PrivacyPolicy } from '@/route'


type Props = {}

type ModalContent = {
  title: string
  body: string
}

const Component: FC<Props> = ({ }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [content, setContent] = useState<ModalContent>({ title: '', body: '' })
  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }
  const onClick = async (data: { email: string, termCheck: boolean }) => {
    try {
      await apiClient.api.mail_magazine.$post({ body: data })
      reset()
      setContent({ title: 'メールマガジン登録', body: 'メールマガジンの登録が完了しました。' })
      openModal()
    } catch (e) {
      setContent({ title: 'メールマガジン登録', body: 'メールマガジンの登録に問題が起きました。問い合わせください' })
      openModal()
    }
  }

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isSubmitting },
  } = useForm<{ email: string, termCheck: boolean }>({
    resolver: zodResolver(schema),
    mode: "all",
  });

  const { field: { value, ...input }, fieldState: { invalid } } = useController({ name: 'termCheck', control })

  return (
    <>
      <form onSubmit={handleSubmit(onClick)} className="w-[320px] xs:w-[342px] xs:h-[280px] p-[19px] md:w-full md:h-56 xl:w-[250px] xl:h-[298px] rounded-[4px] md:p-[14px] shadow  bg-primary-800 md:flex md:flex-row md:items-center md:justify-around md:mt-4 xl:flex-col xl:mt-0">
        <div className="md:w-2/5 xl:w-full">
          <p className="my-4 text-white font-semibold text-lg">メールマガジンに登録する</p>
          <p className="text-white my-4">新着記事やIoTに関連する役立ち情報をお届けします</p>
        </div>

        <div className="md:w-2/5 xl:w-full">
          <Input leftIcon={<IconMail className="text-primary-900 mx-2" width={30} height={30} />} placeholder="メールアドレス" containerClass='bg-white' inputClass='bg-white text-primary-900' {...register('email')} />
          <span className="text-accent-500 text-xs">{errors.email?.message}</span>

          <div className="w-full xl:min-w-max items-center flex gap-1 text-xs text-white mt-2">
            <input {...input}
              type="checkbox"
              className={classNames(
                "accent-white",
                invalid
                  ? "ring-red-500 ring-4"
                  : ''
              )
              }
            />
            <div>
              <a className='text-white underline' href={PrivacyPolicy} rel="noreferrer" target='_blank'>個人情報の取り扱いについて</a>
              <span>に同意する</span>
            </div>
          </div>



          <button disabled={!isValid || isSubmitting} type="submit" className="bg-accent-500 my-4 text-primary-900 py-2 px-5 rounded-[4px] font-semibold disabled:cursor-not-allowed">
            {isSubmitting
              ? <svg className="animate-spin h-5 w-5 mr-3 text-white" viewBox="0 0 24 24" >
                <circle className="opacity-5" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              : <span>登録する</span>
            }
          </button>
        </div>
      </form>
      <Modal title={content.title} body={content.body} isOpen={isOpen} closeModal={closeModal} />
    </>
  )
}

export default Component
