import { FC } from 'react'
import { IconInfoCircle, IconCheck, IconRuler2, IconChevronRight } from '@tabler/icons'
import Link from 'next/link'
import { ArticleCategory } from '@/api/types'
import { CategoryPageBase } from 'src/route'

type Props = {
  categories: ArticleCategory[]
}

const Component: FC<Props> = ({ categories }) => {
  const switchIcon = (name: string) => {
    switch (name) {
      case '知りたい':
        return <IconInfoCircle width={16} height={16} />
      case '課題を解決したい':
        return <IconCheck width={16} height={16} />
      case '比較をしたい':
        return <IconRuler2 width={16} height={16} />
      default:
        return <div />
    }
  }

  return (
    <div className="w-[320px] xs:w-[342px] p-[19px] xl:w-[250px]  xl:p-[14px] flex flex-col gap-4 md:flex-row md:w-full xl:flex-col">
      {categories.map((c) => {
        return (
          <div
            key={c.id}
            className="w-full flex justify-between items-center md:justify-start md:gap-4 xl:justify-between"
          >
            <div className="flex gap-4 items-center">
              {switchIcon(c.name)}
              <Link href={CategoryPageBase(c.id, '1')} prefetch={false}>
                <a>{c.name}</a>
              </Link>
            </div>
            <IconChevronRight width={16} height={16} />
          </div>
        )
      })}
    </div>
  )
}

export default Component
