import { FC } from 'react'
import Image from '@/components/Image'
import { SideBanner } from '@/api/types'

export type Props = SideBanner

const Component: FC<Props> = ({ link, eyeCatching }) => {
  const url = "/iot_banner.png"
  return (
    <>
      <a className="w-full aspect-video block" rel="noreferrer" href={link} target="_blank">
        {/* <Image size="w=100%" url={eyeCatching.url} /> */}
        <Image size="w=100%" url={url} />
      </a>
    </>
  )
}

export default Component
