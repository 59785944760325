import React, { ReactNode } from 'react'
import { classNames } from '@/utils'

export type Props = {
  rightIcon?: ReactNode
  leftIcon?: ReactNode
  containerClass?: string
  inputClass?: string
  message?: string
  invalid?: boolean
}

export type InputProps = JSX.IntrinsicElements['input'] & Props
const Input = React.forwardRef<HTMLInputElement, InputProps>(({ leftIcon, rightIcon, containerClass, inputClass, message, invalid, ...props }, ref) => {
  return (
    <div className="flex flex-col w-full md:w-auto">
      <div className={classNames(
        "flex items-center w-full h-9 rounded-[4px]",
        containerClass
          ? containerClass
          : ''
      )}>
        {leftIcon ? leftIcon : <></>}
        <input
          className={classNames(
            "w-full h-full rounded-[4px] focus:ring-0 focus:ring-transparent ring-0 focus:outline-none",
            inputClass ? inputClass : '',
            invalid ? 'border border-red-500' : 'border-none'
          )}
          ref={ref}
          {...props}
        />
        {rightIcon ? rightIcon : <></>}
      </div>
      {message ? <p className="mt-1 text-xs text-red-500">{message}</p> : <></>}
    </div>
  )
})

Input.displayName = 'Input'

export default Input
