import * as z from 'zod'

const required = '入力しましょう'
const mustEmailForm = 'メールアドレスの形式で入力しましょう'

export const schema = z.object({
  email: z.string().email({ message: mustEmailForm }).nonempty({ message: required }),
})

export default schema
