import api from './api/$api'
import aspida from '@aspida/fetch'

const fetchConfig = {
  headers: {
    'X-MICROCMS-API-KEY': process.env.NEXT_PUBLIC_MICROCMS_API_KEY || '',
  },
  baseURL: process.env.NEXT_PUBLIC_BASE_PATH,
}

const fetchConfigAPI = {
}

export const mediaClient = api(aspida(fetch, fetchConfig))
export const apiClient = api(aspida(fetch, fetchConfigAPI))
