import { FC, useRef } from 'react'
import Link from 'next/link'
import Tag from './Tag'
import { IconClock, IconRefresh } from '@tabler/icons'
import Image from '@/components/Image'
import { ArticleTag } from 'src/api/types'

export type Props = {
  id: string
  eyeCatching: {
    url: string
  }
  title: string
  description: string
  revisedAt?: string
  readTime: string
  tags: ArticleTag[]
  url: string
  tagPrefix?: string
}

const Component: FC<Props> = ({ title, eyeCatching, description, revisedAt, readTime, url, tags, tagPrefix }) => {
  const ref = useRef<HTMLAnchorElement | null>(null)
  const onClickCard = () => {
    if (ref.current != null) {
      ref.current.click()
    }
  }

  const size = 'w=304&h=171'

  return (
    <>
      <div
        onClick={onClickCard}
        className="xs:w-[342px] xs:h-[406px]
        md:w-[332px] md:h-[352px]
        p-[18px]  rounded-[4px] md:p-[16px] shadow hover:shadow-md bg-white hover:border-white hover:opacity-90 max-w-full"
      >
        <div className="rounded-[5px] overflow-hidden aspect-video w-full cursor-pointer">
          <div className="hover:scale-125 transition-transform duration-300">
            <Image url={eyeCatching.url} size={size} />
          </div>
        </div>

        <Link href={url} prefetch={false}>
          <a
            ref={ref}
            className="line-clamp-2 text-xl md:text-base mt-4 font-semibold hover:text-primary-500 inline-block"
          >
            {title}
          </a>
        </Link>

        <section className="flex gap-2 my-4 md:my-2 overflow-hidden">
          {tags.map((tag, idx) => (
            <Tag key={tagPrefix + "-" + tag.id + "-"+ idx} name={tag.name} />
          ))}
        </section>

        <p className="text-base md:text-xs line-clamp-2">{description}</p>

        <div className="flex gap-2 text-xs mt-3 text-gray-600">
          <div className="flex gap-1 items-center">
            <IconRefresh width="12" height="12" />
            <p>{revisedAt}</p>
          </div>
          <div className="flex gap-1 items-center">
            <IconClock width="12" height="12" />
            <p>{readTime}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Component
