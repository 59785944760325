import { FC } from 'react'

type Props = {
  url: string
  size: string
  loading?: "lazy" | "eager"
}

const Component: FC<Props> = ({ url, size, loading }) => {
  const webp = `${url}?fm=webp&${size}`
  const avif = `${url}?fm=avif&${size}`
  const fileName = url.split('/').pop()

  const l = loading ? loading : "lazy"

  return (
    <div className="relative overflow-hidden">
    <picture>
      <source srcSet={avif} type="image/avif" />
      <source srcSet={webp} type="image/webp" />
      <img
        src={url}
        width="100%"
        height="100%"
        alt={fileName}
        loading={l}
        className="w-full h-full transform scale-100 hover:scale-105 transition-transform duration-300"
        style={{
          objectFit: "cover",
        }}
      />
    </picture>
  </div>
  )
}

export default Component
